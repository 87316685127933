import { defineStore } from 'pinia';
import { LeadDto } from '@containex/portal-backend-dto';

interface LeadState {
    leads: LeadDto[];
    amount: number;
}

export const useLeadStore = defineStore('lead', {
    state: (): LeadState => ({
        leads: [],
        amount: 0,
    }),
});
